'use client';

//framer motion
import {motion} from 'framer-motion';
//variants
import {fadeIn} from '../variants';
//icons
import {MdCorporateFare, MdHandshake, MdTrendingUp} from 'react-icons/md';

export default function Why() {
  return (
    <section className='section flex items-center' id='why'>
      <div className='container mx-auto'>
        <motion.h2
          variants={fadeIn('up', 0.2)}
          initial='hidden'
          whileInView={'show'}
          viewport={{once: false, amount: 0.6}}
          className='h3 text-center'
        >
          Unmatched excellence and customer satisfaction
        </motion.h2>
        <motion.p
          variants={fadeIn('up', 0.4)}
          initial='hidden'
          whileInView={'show'}
          viewport={{once: false, amount: 0.6}}
          className='max-w-[700px] text-center mx-auto mb-2'
        >
          Our dedication to providing exceptional service sets us apart from the
          competition.From the moment you engage with us, we strive to exceed
          your expectations in every interaction
        </motion.p>
        {/* car image */}
        <motion.div
          variants={fadeIn('up', 0.6)}
          initial='hidden'
          whileInView={'show'}
          viewport={{once: false, amount: 0.6}}
          className='hidden md:flex justify-center mb-6 lg:mb-2'
        >
          <img src={'/images/why/car10.png'} width={360} height={70} alt='' />
        </motion.div>
        {/* grid items */}
        <motion.div
          variants={fadeIn('up', 0.8)}
          initial='hidden'
          whileInView={'show'}
          viewport={{once: false, amount: 0.4}}
          className='flex flex-wrap justify-center lg:grid lg:grid-cols-3 gap-4 lg:gap-y-0 lg:gap-x-[30px]'
        >
          {/* item 1 */}
          <div className='flex flex-col items-center text-center max-w-[160px] lg:max-w-none p-2 lg:p-0'>
            <MdTrendingUp className='text-[38px] text-accent mb-4' />
            <h3 className='text-base font-semibold'>
              Experience and Expertise
            </h3>
            <p className='text-sm hidden lg:flex'>
              We've been in the car recovery business for years. Our team is
              well-trained and equipped with the latest tools to provide you
              with quick and professional car recovery services.
            </p>
          </div>
          {/* item 2 */}
          <div className='flex flex-col items-center text-center max-w-[160px] lg:max-w-none p-2 lg:p-0'>
            <MdCorporateFare className='text-[38px] text-accent mb-4' />
            <h3 className='text-base font-semibold'>
              Corporate Responsibility
            </h3>
            <p className='text-sm hidden lg:flex'>
              We are committed to provide safe and healthy environment to our
              employees, respect human rights and contribute to the sustainable
              development of communities.
            </p>
          </div>
          {/* item 3 */}
          <div className='flex flex-col items-center text-center max-w-[160px] lg:max-w-none p-2 lg:p-0'>
            <MdHandshake className='text-[38px] text-accent mb-4' />
            <h3 className='text-base font-semibold'>
              Prompt and flexible services
            </h3>
            <p className='text-sm hidden lg:flex'>
              Our standard tow service will pick up the vehicle from the current
              location and deliver the vehicle safely to the destination of your
              choice.
            </p>
          </div>
        </motion.div>
      </div>
    </section>
  );
}
