'use client';

// framer motion
import { motion } from 'framer-motion';
// variants
import { fadeIn } from '../variants';
import { FaPhoneAlt } from 'react-icons/fa';

export default function Cta() {
  return (
    <section
      className='pt-24 lg:py-48 flex items-end pb-0 bg-[#b2b7c2]/10 overflow-hidden'
      id='contact'
    >
      <div className='w-11/12 mx-auto'>
        <div className='flex flex-col md:flex-row lg:items-center'>
          {/* text */}
          <div className='flex-1 lg:ml-24 text-center md:text-left mb-12 md:mb-0'>
            <div className='max-w-[520px] mx-auto order-2 lg:order-none'>
              <motion.h2
                variants={fadeIn('right', 0.2)}
                initial='hidden'
                whileInView={'show'}
                viewport={{ once: false, amount: 0.6 }}
                className='h2'
              >
                24/7 Availability - " Dubai Recovery Service 24 Hours "
              </motion.h2>
              <motion.p
                variants={fadeIn('right', 0.4)}
                initial='hidden'
                whileInView={'show'}
                viewport={{ once: false, amount: 0.6 }}
                className='mb-10'
              >
                Emergencies don't stick to a schedule; they happen at the least
                convenient times. Our car recovery service is available 24 hours
                a day, 7 days a week, to give you peace of mind whenever you're
                on the road.
              </motion.p>
              {/* btn */}
              <motion.div
                variants={fadeIn('right', 0.6)}
                initial='hidden'
                whileInView={'show'}
                viewport={{ once: false, amount: 0.6 }}
                className='flex gap-x-3 justify-center md:justify-start'
              >
                <button
                  onClick={() => {
                    window.open('tel:0524294624');
                  }}
                  className='btn-cta md:px-5 md:py-2 md:text-base text-sm px-2 py-1 text-white flex justify-center items-center'
                >
                  <FaPhoneAlt className='text-white mr-3' />
                  CALL NOW - 052 4294624
                </button>

                <button
                  onClick={() => {
                    window.open('tel:0559190406');
                  }}
                  className='btn-cta md:px-5 md:py-2 md:text-base text-sm px-2 py-1 text-white flex justify-center items-center'
                >
                  <FaPhoneAlt className='text-white mr-3' />
                  CALL NOW - 055 9190406
                </button>
              </motion.div>
            </div>
          </div>
          {/* image */}
          <motion.div
            variants={fadeIn('left', 0.8)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.2 }}
            className='flex-1 flex justify-center order-1 md:order-none'
          >
            <iframe
              title='Map'
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11028.280135544856!2d55.26595374981902!3d25.20103066265881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1697582080643!5m2!1sen!2s'
              width='470'
              height='450'
              style={{ border: '0' }}
              allowfullscreen=''
              loading='lazy'
              className='rounded-lg'
              referrerpolicy='no-referrer-when-downgrade'
            ></iframe>
          </motion.div>
        </div>
      </div>
    </section>
  );
}
