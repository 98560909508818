import { useEffect, useState } from 'react';
//useContext
// react scroll
import { Link } from 'react-scroll';
// import SearchMobile from './SearchMobile';
import { useMediaQuery } from 'react-responsive';
import { BiMenuAltRight, BiX } from 'react-icons/bi';
import { Helmet } from 'react-helmet-async';
// import {SearchContext} from './context/search';

export default function Header() {
  // const {setSearchActive} = useContext(SearchContext);
  const [header, setHeader] = useState(false);
  const [nav, setNav] = useState(false);
  const desktopMode = useMediaQuery({ query: '(min-width:1300px)' });

  useEffect(() => {
    const handleScroll = () => {
      //header
      if (window.scrollY > 40) {
        setHeader(true);
      } else {
        setHeader(false);
      }

      //search
      // if (window.scrollY > 800) {
      //   setSearchActive(true);
      // } else {
      //   setSearchActive(false);
      // }
    };
    //add event listener
    window.addEventListener('scroll', handleScroll);
    // remove  event
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <header
      className={`${
        header ? `bg-white shadow-md py-2` : `bg-transparent shadow-none py-4`
      } fixed w-full max-w-[1920px] mx-auto z-20  transition-all duration-300 `}
    >
      <Helmet>
        <title>Dubai Recovery Services!</title>
        <meta
          name='description'
          content='car, vehicle tow and recovery services in dubai, abu dhabi, sharjah'
        />
        {/* End standard metadata tags */}
        {/* Facebook tags */}
        <meta property='og:type' content='website' />
        <meta property='og:image' content='/images/drs-preview.jpg' />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta name='twitter:image' content='/images/drs-preview.jpg' />
        <meta
          property='og:title'
          content='Dubai Recovery Services | Vehicle Tow and Recovery Services'
        />
        <meta
          property='og:description'
          content='Call us for roadside and off-road vehicle assistance. We strive
              for in-time and quick delivery of vehicles and recovery services.'
        />
        {/* End Facebook tags */}
        {/* Twitter tags */}
        <meta name='twitter:card' content='/icons/drs-logo.png' />
        <meta name='twitter:image' content='/images/drs-preview.jpg' />
        <meta name='twitter:creator' content='' />
        <meta
          name='twitter:title'
          content='Dubai Recovery Services | Vehicle Tow and Recovery Services'
        />
        <meta
          name='twitter:description'
          content='Call us for roadside and off-road vehicle assistance. We strive
              for in-time and quick delivery of vehicles and recovery services.'
        />
      </Helmet>
      <div className='cursor-pointer flex justify-end items-end relative'>
        <img
          className='absolute -top-4 md:w-36 md:h-36 w-28 h-28 rounded-b-2xl md:mx-10 mx-14'
          src={`/images/dial-card.jpg`}
          alt=''
        />
      </div>
      <div className='lg:container mx-auto flex flex-col lg:flew-row lg:items-center lg:justify-between'>
        <span className='flex justify-between  items-center px-4'>
          {/* logo */}
          <Link
            to='home'
            smooth={desktopMode}
            spy={true}
            className='cursor-pointer'
          >
            <img src={`/images/drs-logo.png`} width={70} height={20} alt='' />
          </Link>
          <div
            onClick={() => setNav(!nav)}
            className='cursor-pointer lg:hidden'
          >
            {nav ? (
              <BiX className='text-4xl' />
            ) : (
              <BiMenuAltRight className='text-4xl' />
            )}
          </div>
          {/* nav */}
        </span>
        <nav
          className={`${
            nav
              ? `max-h-max py-8 px-4 lg:py-0 lg:px-0 `
              : `max-h-0 lg:max-h-max`
          } flex flex-col w-full bg-white gap-y-6 overflow-hidden font-bold lg:font-medium lg:flex-row lg:w-max lg:gap-x-8 lg:h-max lg:bg-transparent lg:pb-0 transition-all duration-150 text-center lg:text-left uppercase text-sm lg:text-[15px] lg:normal-case`}
        >
          <Link
            className='cursor-pointer'
            to='home'
            activeClass='active'
            smooth={desktopMode}
            spy={true}
          >
            Home
          </Link>
          <Link
            className='cursor-pointer'
            to='services'
            activeClass='active'
            smooth={desktopMode}
            spy={true}
          >
            Services
          </Link>
          <Link
            className='cursor-pointer'
            to='about'
            activeClass='active'
            smooth={desktopMode}
            spy={true}
          >
            About
          </Link>
          <Link
            className='cursor-pointer'
            to='why'
            activeClass='active'
            smooth={desktopMode}
            spy={true}
          >
            Why us
          </Link>
          <Link
            className='cursor-pointer'
            to='contact'
            activeClass='active'
            smooth={desktopMode}
            spy={true}
          >
            Contact
          </Link>
        </nav>
      </div>
    </header>
  );
}
