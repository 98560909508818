'use client';

// import {useContext} from 'react';
// motion
import { motion } from 'framer-motion';
// variants
import { fadeIn } from '../variants';
import { FaPhoneAlt } from 'react-icons/fa';
// import {SearchContext} from './context/search';

export default function Hero() {
  // const {searchActive} = useContext(SearchContext);
  return (
    <section className='h-screen lg:h-[90vh] bg-[#b2b7c2]/10' id='home'>
      <div className='container mx-auto h-full lg:pt-10 pt-36'>
        {/* text & img wrapper*/}
        <div className='flex flex-col lg:flex-row justify-center items-center lg:justify-start h-full'>
          {/* text */}
          <div className='text-center lg:max-w-xl lg:text-left mt-2 lg:mt-0'>
            <motion.h1
              variants={fadeIn('down', 0.2)}
              initial='hidden'
              whileInView={'show'}
              viewport={{ once: false, amount: 0.6 }}
              className='md:h1 text-lg font-bold'
            >
              Welcome to Dubai Recovery{' '}
              <span className='text-accent'> Service.</span>
            </motion.h1>
            <motion.h1
              variants={fadeIn('down', 0.4)}
              initial='hidden'
              whileInView={'show'}
              viewport={{ once: false, amount: 0.6 }}
              className='description max-w-[550px] mx-auto lg:mx-0 mb-6 lg:mb-10 md:text-base text-sm'
            >
              Call us for roadside and off-road vehicle assistance. We strive
              for in-time and quick delivery of vehicles and recovery services.
            </motion.h1>
            <motion.div
              variants={fadeIn('down', 0.4)}
              initial='hidden'
              whileInView={'show'}
              viewport={{ once: false, amount: 0.6 }}
              className='text-black my-5 flex justify-center items-center font-bold md:text-base text-sm'
            >
              AFFORDABLE - RELIABLE - EFFICIENT
            </motion.div>
            {/* btn */}
            <motion.div
              variants={fadeIn('down', 0.6)}
              initial='hidden'
              whileInView={'show'}
              viewport={{ once: false, amount: 0.8 }}
              className='flex gap-x-3 justify-center lg:justify-start'
            >
              {/* btn appStore */}
              <button
                onClick={() => {
                  window.open('tel:0524294624');
                }}
                className='btn-cta md:px-5 md:py-2 md:text-base text-sm px-2 py-1 text-white flex justify-center items-center'
              >
                <FaPhoneAlt className='text-white mr-3' />
                CALL NOW - 052 4294624
              </button>
            </motion.div>
          </div>
          {/* img car */}
          <motion.div
            variants={fadeIn('up', 0.6)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.6 }}
            className='relative md:w-full md:h-full my-5 w-72 h-72 max-h-[50vh] md:max-w-[70vw] lg:max-w-[480px] lg:max-h-[300px] lg:absolute lg:-right-[0px] min-[1680px]:right-[120px] lg:top-32'
          >
            <img
              src={`/images/hero/mainImage.png`}
              fill
              alt=''
              style={{ objectFit: 'contain' }}
              priority
            />
          </motion.div>
        </div>
      </div>
    </section>
  );
}
