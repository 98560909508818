//icons
import { FaPhone, FaEnvelope } from 'react-icons/fa6';
//components
import Copyright from './Copyright';
//motion
// import {motion} from 'framer-motion';
//variants
import { fadeIn } from '../variants';
import { FaWhatsapp } from 'react-icons/fa';
//link / react scroll
import { Link } from 'react-scroll';

export default function Footer() {
  return (
    <footer className='pt-20 bg-white z-20' id='contact'>
      <div className='container mx-auto md:mb-24 mb-5'>
        {/* grid */}
        <div
          variants={fadeIn('up', 0.2)}
          initial='hidden'
          whileInView={'show'}
          viewport={{ once: false, amount: 0.6 }}
          className='flex flex-col mx-auto lg:flex-row lg:justify-between gap-x-5 gap-y-14'
        >
          <div className='flex flex-col flex-1 gap-y-8'>
            {/* logo */}
            <Link
              href='/'
              to={'home'}
              smooth={true}
              spy={true}
              className='cursor-pointer'
            >
              <img
                src={'/images/drs-logo.png'}
                width={100}
                height={100}
                alt=''
              />
            </Link>
            {/* text */}
            <div className='text-secondary text-sm'>
              Dubai Recovery Service provide you the best, fastest and reliable
              services.
            </div>
            {/* phone & email */}
            <div className='flex flex-col gap-y-4 font-semibold'>
              <div className='flex items-center gap-x-[10px]'>
                <FaPhone />
                <div className='font-medium'>052 4294624</div>
              </div>
              {/* email */}
              <div className='flex items-center gap-x-[10px]'>
                <FaEnvelope />
                <div className='font-medium'>
                kasanarecoveryservice@gmail.com
                </div>
              </div>
            </div>
          </div>
          {/* links */}
          <div className='flex-1 flex flex-col lg:items-center'>
            <div>
              <h3 className='h3 font-bold mb-8'>Our Services</h3>
              <ul className='flex flex-col gap-y-4 font-semibold'>
                <li>
                  <a href='/#services'>Bike Recovery Services</a>
                </li>
                <li>
                  <a href='/#services'>Recovery Services</a>
                </li>
                <li>
                  <a href='/#services'>Fuel Delivery Services</a>
                </li>
                <li>
                  <a href='/#services'>Towing Services</a>
                </li>
                <li>
                  <a href='/#services'>Sports Car Towing</a>
                </li>
                <li>
                  <a href='/#services'>Roadside Assistance Services</a>
                </li>
              </ul>
            </div>
          </div>
          {/* program */}
          <div className='flex-1'>
            <h3 className='h3 font-bold mb-8'>Working Hours</h3>
            <div className='flex flex-col gap-y-4'>
              <div className='flex gap-x-2'>
                <div className='text-secondary'>Mon-Sun:</div>
                <div className='font-semibold'>12:00AM - 11:59PM</div>
              </div>
              <div className='flex gap-x-2'>
                <div className='text-secondary'>Available:</div>
                <div className='font-semibold'>24/7</div>
              </div>
            </div>
          </div>
          {/* Services Rote */}
          <div className='flex-1'>
            <div>
              <h3 className='h3 font-bold mb-8'>
                Our Services Rote From Dubai to
              </h3>
              <ul className='flex flex-col gap-y-4 font-semibold'>
                <li>
                  <button href='#'>Abu Dhabi</button>
                </li>
                <li>
                  <button href='#'>Sharjah</button>
                </li>
                <li>
                  <button href='#'>Al Ain</button>
                </li>
                <li>
                  <button href='#'>Ajman</button>
                </li>
                <li>
                  <button href='#'>Dubai Downtown</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='fixed bottom-4 right-4 z-20 group hover:bg-[#25D366] rounded-full'>
        <a
          rel='noreferrer'
          href='https://wa.me/+971524294624'
          target='_blank'
          className='hover:cursor-pointer text-[#25D366] hover:text-white'
        >
          <FaWhatsapp size={56} />
        </a>
      </div>
      <Copyright />
    </footer>
  );
}
