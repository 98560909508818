'use client';

import {motion} from 'framer-motion';
//variants
import {fadeIn} from '../variants';

export default function DialNo() {
  return (
    <section className='md:my-5 my-16' id=''>
      <motion.div
        variants={fadeIn('up', 0.4)}
        initial='hidden'
        whileInView={'show'}
        viewport={{once: false, amount: 0.6}}
        className='w-full h-full mx-auto'
      >
        <img
          src={`/images/DialNo/Call-Now.png`}
          width={1600}
          height={600}
          alt=''
        />
      </motion.div>
    </section>
  );
}
