//framer
import { motion } from 'framer-motion';
//variants
import { fadeIn } from '../variants';

export default function Services() {
  return (
    <section
      className='py-10 lg:py-32 flex items-end pb-0  overflow-hidden'
      id='services'
    >
      <motion.div
        variants={fadeIn('up', 0.4)}
        initial='hidden'
        whileInView={'show'}
        viewport={{ once: false, amount: 0.2 }}
        className='mx-auto'
      >
        <motion.div
          variants={fadeIn('up', 0.4)}
          initial='hidden'
          whileInView={'show'}
          viewport={{ once: false, amount: 0.2 }}
          className='text-4xl mb-5 font-semibold text-black uppercase flex justify-center items-center '
        >
          Our Services
        </motion.div>
        <div className='mx-auto container grid grid-cols-3 gap-6 place-items-center lg:flex lg:flex-wrap lg:gap-x-6 lg:justify-between '>
          <div>
            <img
              src='/images/carSlider/Towing Service.png'
              width={300}
              height={170}
              alt=''
            />
          </div>
          <div>
            <img
              src='/images/carSlider/sportCar.png'
              width={300}
              height={170}
              alt=''
            />
          </div>
          <div>
            <img
              src='/images/carSlider/Bike.png'
              width={300}
              height={170}
              alt=''
            />
          </div>
          <div>
            <img
              src='/images/carSlider/Fuel-DS.png'
              width={300}
              height={170}
              alt=''
            />
          </div>
          <div>
            <img
              src='/images/carSlider/recovery-S.png'
              width={300}
              height={170}
              alt=''
            />
          </div>
          <div>
            <img
              src='/images/carSlider/LockOut.png'
              width={300}
              height={170}
              alt=''
            />
          </div>
        </div>
      </motion.div>
    </section>
  );
}
