//react intersection observer
// import {useInView} from 'react-intersection-observer';

//motion
import { motion } from 'framer-motion';

//variants
import { fadeIn } from '../variants';

const About = () => {
  // const [ref, inView] = useInView({
  //   threshold: 0.5,
  // });
  return (
    <section className='md:mt-0 mt-32 section flex items-center' id='about'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row lg:justify-between'>
          {/* image */}
          <motion.div
            variants={fadeIn('up', 0.2)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.6 }}
            className='flex-1 mb-8 lg:mb-0'
          >
            <img
              className='rounded-[20px] md:w-92 md:h-92 '
              src={'/images/about/car9.jpg'}
              // width={600}
              // height={448}
              alt=''
            />
          </motion.div>
          {/* text & stats */}
          <div className='flex-1 flex items-center lg:justify-center'>
            <div className='lg:max-w-[517px]'>
              <motion.h2
                variants={fadeIn('up', 0.2)}
                initial='hidden'
                whileInView={'show'}
                viewport={{ once: false, amount: 0.6 }}
                className='h3 font-bold'
              >
                Professional & Fast Towing Services Near You
              </motion.h2>
              <motion.p
                variants={fadeIn('up', 0.6)}
                initial='hidden'
                whileInView={'show'}
                viewport={{ once: false, amount: 0.6 }}
                className='mb-[42px] max-w-md'
              >
                <p className='my-2 font-semibold'>ROADSIDE FOR ALL SIZES </p>
                <ul>
                  <li>
                    <p>• All vehicle type</p>{' '}
                  </li>
                </ul>
                <ul>
                  <li>
                    <p>• Any vehicle class</p>
                  </li>
                </ul>
                <ul>
                  <li>
                    <p>• Any number of vehicles Anywhere in UAE.</p>
                  </li>
                </ul>
                <ul>
                  <li>
                    <p>• A Any time of the day or night</p>
                  </li>
                </ul>
                <ul>
                  <li>
                    <p>• Any day of the year 24/7 LIVE Call Center</p>
                  </li>
                </ul>
                <p className='my-2 font-semibold'>24-Hour Service Areas</p>
                <ul>
                  <li>
                    <h4>• Abu Dhabi </h4>
                  </li>
                </ul>
                <ul>
                  <li>
                    <h4>• Sharjah</h4>
                  </li>
                </ul>
                <ul>
                  <li>
                    <h4>• Al Ain</h4>
                  </li>
                </ul>
                <ul>
                  <li>
                    <h4>• Ajman</h4>
                  </li>
                </ul>
                <ul>
                  <li>
                    <h4>• Dubai Downtown</h4>
                  </li>
                </ul>
              </motion.p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
