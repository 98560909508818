import Header from './components/Header';
import Footer from './components/Footer';
import Hero from './components/Hero';
import About from './components/About';
import Why from './components/Why';
import Cta from './components/Cta';
import BackToTopBtn from './components/BackToTopBtn';
import Services from './components/Services';
import DialNo from './components/DialNo';

function App() {
  return (
    <div className=''>
      <main className='max-w-screen bg-white mx-auto relative overflow-hidden'>
        <Header />
        <Hero />
        <DialNo />
        <Services />
        <About />
        <Why />
        <Cta />
        <Footer />
        <BackToTopBtn />
      </main>
    </div>
  );
}

export default App;
